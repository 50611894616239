import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Quiz } from "../types/pubQuiz";
import CloseIcon from "@mui/icons-material/Close";
import { Card, CardContent, IconButton, Typography } from "@mui/material";

export const SlidingBox = (props: {
  selectedQuiz: Quiz | undefined;
  onClose: any;
}) => {
  return (
    <Card
      elevation={3}
      sx={{
        position: "fixed",
        bottom: 0,
        left: "25%",
        right: "25%",
        background: "#f0f0f0",
        padding: "10px",
        transition: "transform 0.3s ease-in-out",
        transform: props.selectedQuiz
          ? "translateY(calc(100% - 4cm))"
          : "translateY(100%)",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          direction: "row",
          justifyContent: "center"
        }}
      >
        {props.selectedQuiz && (
          <div>
            <IconButton
              aria-label="Close"
              size="small"
              onClick={props.onClose}
              sx={{
                position: "absolute",
                top: 5,
                right: 5,
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
            <Typography variant="h6" >{props.selectedQuiz?.pubName}</Typography>
            <Typography variant="body2" color="text.secondary">{props.selectedQuiz.infoLong}</Typography>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default SlidingBox;
