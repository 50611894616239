import { useState } from "react";
import "../App.scss";
import BasicTable from "../components/quizTable";
import { WeekdaySelector } from "../components/weekdaySelector";
import { Quiz } from "../types/pubQuiz";
import Map from "../components/map";
import SlidingBox from "../components/slidingBox";

const Home = () => {
  const [displayedQuizes, setDisplayedQuizes] = useState<Quiz[]>([]);
  const [hoveredQuiz, setHoveredQuiz] = useState<Quiz>();
  const [selectedQuizInfo, setSelectedQuizInfo] = useState<Quiz>();

  const handleQuizSelect = (quiz: Quiz) => {
    setSelectedQuizInfo(quiz);
  };

  const handleBoxClose = () => {
    setSelectedQuizInfo(undefined);
  };

  return (
    <>
      <h2>Finn en quiz nær deg</h2>
      <div className="splitScreen">
        <div className="leftPane">
          <WeekdaySelector
            onFilterQuiz={setDisplayedQuizes}
            onHoverQuiz={setHoveredQuiz}
            onQuizInfoClick={handleQuizSelect}
            selectedQuizInfo={selectedQuizInfo}
          ></WeekdaySelector>
        </div>
        <div className="rightPane">
          <Map
            displayedQuizes={displayedQuizes}
            hoveredQuiz={hoveredQuiz}
          ></Map>
        </div>
      </div>
      <SlidingBox selectedQuiz={selectedQuizInfo} onClose={handleBoxClose}></SlidingBox>
    </>
  );
};

export default Home;
