import { Routes, Route } from 'react-router-dom';
import Home from '../pages/home';

const Main = () => {
return ( 
    <div className="app"> 
        <div className="wrapper">     
        <Routes>
        <Route path='/' element={<Home/>} />
        </Routes>
        </div>
    </div>
);
}
export default Main;