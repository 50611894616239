import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Quiz } from "../types/pubQuiz";
import { Button, Link } from "@mui/material";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const WeekdaySelector = (props: {
  onFilterQuiz: any;
  onHoverQuiz: any;
  onQuizInfoClick: any;
  selectedQuizInfo: Quiz | undefined;
}) => {
  const [selectedDays, setSelectedDays] = useState<string[]>([]);

  const filteredQuizzes = quizes.filter((quiz) =>
    quiz.days.some((day) => selectedDays.includes(day))
  );

  useEffect(() => {
    props.onFilterQuiz(filteredQuizzes);
  }, [filteredQuizzes]);

  return (
    <>
      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        options={weekdays}
        disableCloseOnSelect
        getOptionLabel={(option) => option}
        onChange={(_event, value) => setSelectedDays(value)}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option}
          </li>
        )}
        style={{ marginTop: 10, marginBottom: 30 }}
        renderInput={(params) => <TextField {...params} label="Dag" />}
      />

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            {filteredQuizzes.map((row) => (
              <TableRow
                onMouseLeave={() => props.onHoverQuiz(null)}
                onMouseEnter={() => props.onHoverQuiz(row)}
                key={row.pubName}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  cursor: "pointer", // Change cursor on hover
                  backgroundColor:
                    props.selectedQuizInfo === row ? "#efefef" : "inherit", // Highlight selected row
                  "&:hover": {
                    backgroundColor: "#f5f5f5",
                  },
                }}
              >
                <TableCell component="th" scope="row">
                  { row.link ? <Link target="_blank" href={"//"+row.link} underline="hover" > {row.pubName}</Link> : row.pubName}
                </TableCell>
                <TableCell align="right">
                  {row.days + ` (${row.dayMetadata})`}
                </TableCell>
                <TableCell align="right">{row.time}</TableCell>
                <TableCell align="right">{row.infoShort}</TableCell>
                <TableCell align="right">
                  <Button
                    className="circle"
                    onClick={() => props.onQuizInfoClick(row)}
                  >
                    Info
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const weekdays = ["Mandag", "Tirsdag"];

const quizes: Quiz[] = [
  {
    pubName: "Postkontoret",
    id: "postkontoret1",
    days: ["Mandag"],
    dayMetadata: "Første og tredje helg i måneden",
    positionLat: 59.91436782072344,
    positionLng: 10.775135330034027,
    infoShort: "Allmenn",
    infoLong:
      "Dette er en kjempebra quiz blablabla husk å reservere bord bra premie osv",
    time: "19:00",
    city: "Oslo",
    link: "pktoyen.no/postkontoret/eventer",
  },
  {
    id: "asylet1",
    pubName: "Asylet",
    days: ["Tirsdag"],
    dayMetadata: "Første og tredje helg i måneden",
    positionLat: 59.95436782072344,
    positionLng: 10.775135330034027,
    infoShort: "Allmenn",
    infoLong:
      "Dette er en kjempebra quiz blablabla husk å reservere bord bra premie osv",
    time: "19:00",
    city: "Oslo",
    link: "https://www.pktoyen.no/",
  },
];
