import {
  GoogleMap,
  InfoWindow,
  InfoWindowF,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import { useEffect, useMemo, useState } from "react";
import { Quiz } from "../types/pubQuiz";

const Map = (props: {
  displayedQuizes: Quiz[];
  hoveredQuiz: Quiz | undefined;
}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "",
  });

  const [mapRef, setMapRef] = useState<google.maps.Map>();
  type InfoWindowData = { id: string; pubName: string };
  const [infoWindowData, setInfoWindowData] = useState<InfoWindowData | undefined>();

  useEffect(() => {
    setInfoWindowData(props.hoveredQuiz);
  }, [props.hoveredQuiz]);

  const center = useMemo(
    () => ({ lat: 59.9139273040813, lng: 10.746699109567611 }),
    []
  );

  const handleMarkerClick = (
    id: string,
    lat: number,
    lng: number,
    pubName: string
  ) => {
    mapRef?.panTo({ lat, lng });
    setInfoWindowData({ id, pubName });
  };

  const onMapLoad = (map: google.maps.Map) => {
    setMapRef(map);
  };

  useEffect(() => {
    if (
      infoWindowData &&
      !props.displayedQuizes.some((q) => q.id === infoWindowData.id)
    ) {
      setInfoWindowData(undefined);
    }
  }, [props.displayedQuizes]);

  return !isLoaded ? (
    <h1>Loading...</h1>
  ) : (
    <GoogleMap
      mapContainerClassName="map-container"
      center={center}
      zoom={12}
      onClick={() => setInfoWindowData(undefined)}
      onLoad={onMapLoad}
    >
      {props.displayedQuizes.map(
        ({ positionLat, positionLng, id, pubName }) => (
          <Marker
            position={{ lat: positionLat, lng: positionLng }}
            key={id}
            title={pubName}
            onClick={() => {
              handleMarkerClick(id, positionLat, positionLng, pubName);
            }}
          >
            {infoWindowData && infoWindowData?.id === id && (
              <InfoWindowF
                onCloseClick={() => {
                  setInfoWindowData(undefined);
                }}
                key={id}
              >
                <h3>{infoWindowData.pubName}</h3>
              </InfoWindowF>
            )}
          </Marker>
        )
      )}
    </GoogleMap>
  );
};

export default Map;
